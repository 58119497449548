define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-us", {
    "actions": {
      "add": "Add",
      "add-host": "Create Host",
      "add-host-set": "Create Host Set",
      "add-hosts": "Add Hosts",
      "add-members": "Add Members",
      "add-principals": "Add Principals",
      "authenticate": "Sign In",
      "back": "Back",
      "cancel": "Cancel",
      "change-cluster-url": "Change Cluster URL",
      "change-password": "Change Password",
      "change-state": "Change State",
      "choose-different-org": "Choose a different scope",
      "choose-org": "Choose Scope",
      "clear-all-filters": "Clear all filters",
      "close": "Close",
      "confirm": "Confirm",
      "copy-error-detail-to-clipboard": "Copy error detail to clipboard",
      "copy-to-clipboard": "Copy to Clipboard",
      "deauthenticate": "Sign Out",
      "delete": "Delete",
      "deny": "Deny",
      "discard": "Discard",
      "disconnect": "Disconnect",
      "dismiss": "Dismiss",
      "done": "Done",
      "edit-form": "Edit Form",
      "fullscreen": "Fullscreen",
      "get-topic-help": "Get help for this topic",
      "help": "Need help?",
      "learn-more": "Learn more",
      "manage": "Manage",
      "minimize": "Minimize",
      "next": "Next",
      "ok": "OK",
      "refresh": "Refresh",
      "remove": "Remove",
      "retry": "Retry",
      "save": "Save",
      "set-password": "Set Password",
      "submit": "Submit",
      "toggle-secret": "Toggle secret visibility"
    },
    "auth": {
      "descriptions": {
        "authenticating-with-org": "Scope:  You are authenticating with organization \"{name}\"",
        "choose-org": "Choose a scope to authenticate.",
        "flow-error": "Something went wrong while authenticating.  Please close this window and try again.  If the problem persists, notify your administrator.",
        "flow-success": "You've successfully authenticated.  You may now close this window and return to the client.",
        "no-scopes": "No scopes were found.  To authenticate, ask your administrator to create an scope and grant you access."
      },
      "titles": {
        "flow-error": "Could not authenticate",
        "flow-success": "Successful authentication",
        "no-scopes": "No Scopes Available"
      }
    },
    "cluster-url": {
      "connected-to": "Connected to"
    },
    "descriptions": {
      "choose-a-provider": "There are several built-in plugins for the most common providers. You can choose from on of the included plugins or use a custom plugin.",
      "choose-a-provider-azure": "Boundary does not yet support rotating credentials.",
      "cluster-url-initialization": "To get started, please enter your cluster URL",
      "create-but-not-list": "You do not have permission to list {resource}, but you can create new instances.",
      "empty-set": "There are no items to display yet.  You may be able to add items or try back later.",
      "neither-list-nor-create": "You do not have permission to list or create {resource}.",
      "no-results": "We found no matching records.",
      "provider": "This is the provider for this host catalog. The format of the host set filters are specific to each provider."
    },
    "errors": {
      "401": {
        "description": "You are not signed in. Please sign in and try again later.",
        "title": "You are not signed in"
      },
      "403": {
        "description": "You must be granted permissions to view this data. Ask your administrator if you think you should have access.",
        "title": "You are not authorized"
      },
      "404": {
        "description": "We could not find the requested resource. You can ask your administrator or try again later.",
        "title": "Resource not found"
      },
      "500": {
        "description": "We ran into a problem and could not continue. You can ask your administrator or try again later.",
        "title": "Server error"
      },
      "authentication-failed": {
        "title": "Authentication Failed"
      },
      "cluster-url-verification-failed": {
        "description": "Unable to connect to the specified cluster URL. Please check the URL and try again."
      },
      "generic": {
        "title": "Error"
      },
      "quick-setup-failed": {
        "description": "We're sorry, quick setup was unable to generate all resources."
      },
      "unknown": {
        "description": "We're not sure what happened.  Please contact your administrator or try again later.",
        "title": "Something went wrong"
      }
    },
    "form": {
      "access_key_id": {
        "help": "The access key ID generated by AWS for the IAM user to use with this host catalog.",
        "label": "Access Key ID"
      },
      "account_claim_map": {
        "label": "Account Claim Map"
      },
      "account_claim_maps": {
        "help": "Optional claim maps from custom claims to the standard claims of sub, name and email.  These maps are represented as key=value where the key equals the from_claim and the value equals the to_claim.  For example \"oid=sub\".\n",
        "label": "Account Claim Maps"
      },
      "address": {
        "help": "An IP, host name, or other routable address",
        "label": "Address"
      },
      "algorithm": {
        "label": "Algorithm"
      },
      "allowed_audiences": {
        "help": "Allowed audience claims for this auth method.",
        "label": "Allowed Audiences"
      },
      "api_url_prefix": {
        "help": "The prefix that should be used for any URLs needed during the authentication flow. This includes the callback URL, the token retrieval URL, and the redirection URL used by the OIDC Authorization Server.",
        "label": "API URL Prefix"
      },
      "callback_url": {
        "help": "The URL to which the third-party provider sends the authentication response.  Paste this into the provider configuration.",
        "label": "Callback URL"
      },
      "certificate": {
        "label": "Certificate"
      },
      "claim": {
        "label": "Claim"
      },
      "claims_scope": {
        "label": "Claims Scope"
      },
      "claims_scopes": {
        "help": "Optional claims scopes that will be requested during authentication.",
        "label": "Claims Scopes"
      },
      "client_id": {
        "help": "An OAuth 2.0 Client Identifier valid at the Authorization Server.",
        "label": "Client ID"
      },
      "client_secret": {
        "help": "The client secret is set-only and may not be unset.",
        "label": "Client Secret"
      },
      "client_secret_hmac": {
        "label": "Client Secret HMAC"
      },
      "cluster-url": {
        "description": "The Boundary URL to which to connect (e.g. protocol://host:port)",
        "label": "Cluster URL"
      },
      "current_password": {
        "label": "Current Password"
      },
      "default_port": {
        "help": "The default port on which to connect",
        "label": "Default Port"
      },
      "description": {
        "help": "Description for identification purposes",
        "label": "Description"
      },
      "disable_credential_rotation": {
        "label": "Disable credential rotation"
      },
      "disable_discovered_config_validation": {
        "help": "If the Authorization Server's discovered configuration contains values that do not match the configuration set on this auth method, this can be set to force the local configuration to override the discovered values.  This is useful in the case of slow-changing or incorrect values announced by the Authorization Server. NOTE: For safety, not providing this value will be treated the same as \"false\"; that is, every time the method is updated or the state is changed, this value must be set to \"true\" or it will be disabled.\n",
        "label": "Disable discovered config validation"
      },
      "dns_names": {
        "label": "Latest DNS Names"
      },
      "email": {
        "label": "Email"
      },
      "filter": {
        "help": "A boolean expression defining a filter run against the provided information.",
        "label": "Filter"
      },
      "from_claim": {
        "label": "From Claim"
      },
      "full_name": {
        "label": "Full Name"
      },
      "grant": {
        "help": "A permissions grant string for a role",
        "label": "Grant"
      },
      "grant_scope": {
        "help": "Specify the scope to which this role applies.",
        "label": "Grant Scope"
      },
      "host": {
        "label": "Host"
      },
      "host_set": {
        "label": "Host Set"
      },
      "id": {
        "label": "ID"
      },
      "idp_ca_certs": {
        "help": "Optional PEM-encoded X.509 CA certificates that can be used as trust anchors when connecting to an OIDC provider.",
        "label": "Certificates"
      },
      "ip_addresses": {
        "label": "Latest IP Addresses"
      },
      "issuer": {
        "help": "The issuer URL. Boundary expects only the schema, host, and port and will strip off \".well-known/openid-configuration\" if present. This will be used for configuration discovery as well as for validation of the \"iss\" claim.",
        "label": "Issuer"
      },
      "key": {
        "label": "Key"
      },
      "login_name": {
        "label": "Login Name"
      },
      "max_age": {
        "help": "The OIDC \"max_age\" parameter sent to the Authorization Server indicating a maximum acceptable time in seconds since the user's last authentication before requiring the user to reauthenticate. 0 indicates an immediate need to reauthenticate.",
        "label": "Maximum Age"
      },
      "name": {
        "help": "Name for identification purposes",
        "label": "Name"
      },
      "new_password": {
        "label": "New Password"
      },
      "password": {
        "label": "Password"
      },
      "proxy": {
        "label": "Proxy"
      },
      "secret": {
        "help": "The client secret value generated by Azure for Boundary to use for authentication.",
        "label": "Client Secret Value"
      },
      "secret_access_key": {
        "help": "The secret access key generated by AWS for the IAM user to use with this host catalog.",
        "label": "Secret Access Key"
      },
      "session_connection_limit": {
        "help": "The maximum number of connections allowed per session.  For unlimited, specify \"-1\".",
        "label": "Maximum Connections"
      },
      "session_max_seconds": {
        "help": "The maximum duration of a session.",
        "label": "Maximum Duration (in seconds)"
      },
      "set_password": {
        "help": "Create a new password",
        "label": "Password"
      },
      "signing_algorithms": {
        "help": "The signing algorithms allowed for the auth method.",
        "label": "Signing Algorithms"
      },
      "started": {
        "label": "Started"
      },
      "status": {
        "label": "Status"
      },
      "subject": {
        "label": "Subject"
      },
      "subscription_id": {
        "help": "The subscription ID for the subscription with read access.",
        "label": "Subscription ID"
      },
      "sync-interval": {
        "help": "Optional. Number of seconds between the time Boundary syncs hosts in this set.",
        "label": "Sync Interval"
      },
      "tags": {
        "label": "Tags"
      },
      "tenant_id": {
        "help": "The tenant (directory) ID for your Azure Active Directory application.",
        "label": "Tenant/Directory ID"
      },
      "to_claim": {
        "label": "To Claim"
      },
      "type": {
        "help": "The type of the resource, to help differentiate schemas",
        "label": "Type"
      },
      "username": {
        "label": "Username"
      },
      "value": {
        "label": "Value"
      },
      "worker_address": {
        "help": "URL and port for this worker."
      },
      "worker_authorized_actions": {
        "label": "Authorized Actions"
      },
      "worker_filter": {
        "help": "Optional boolean expression to filter the workers that are allowed to satisfy this request.",
        "label": "Worker Filter"
      },
      "worker_last_seen": {
        "help": "Last time the worker reported status.",
        "label": "Last Seen"
      },
      "worker_release_version": {
        "help": "The version of the Boundary binary the worker is running.",
        "label": "Release Version"
      },
      "worker_type": {
        "help": "Type of worker"
      }
    },
    "notifications": {
      "add-success": "Added successfully.",
      "canceled-success": "Canceled successfully.",
      "create-success": "Created successfully.",
      "delete-success": "Deleted successfully.",
      "remove-success": "Removed successfully.",
      "save-success": "Saved successfully."
    },
    "onboarding": {
      "quick-start": {
        "descriptions": {
          "choose-path": "You’ve taken the first step towards making your infrastructure more secure. How do you want to get started?",
          "create-resource": "In Boundary, a “target” defines a server that your users will connect to.",
          "host-address": "Example: “192.168.0.1” or IPv6",
          "manual": "I want to set up my first scopes and targets myself, starting with my first org.",
          "quick-setup": "I want to see how Boundary works, help me set up my first scopes and targets.",
          "resource-setup-info": "Hosts are organized in host catalogs, which live in projects, which live in orgs. We will create these for you, and they can be edited or deleted later.",
          "target-port": "Example: “22”"
        },
        "titles": {
          "choose-path": "Welcome to Boundary",
          "create-resource": "Creating your first target",
          "host-address": "Host Address",
          "manual": "Manual",
          "quick-setup": "Quick setup",
          "resource-setup-info": "We’ll set up scopes & a host catalog for you",
          "target-port": "Target Port",
          "toggle-resource-info": "I have an IP address and port for my first host"
        }
      },
      "success": {
        "descriptions": {
          "controller": "Use this URL to sign in to Boundary",
          "developers": "You can also connect to targets using the Boundary CLI or API.",
          "download": "Download the Boundary Desktop app to browse and connect to targets (available for Windows, Mac, and Linux)",
          "role": "You can now connect to the target with the Boundary Desktop app, command-line (CLI) or API. Boundary grants access to resources based on roles, and we have added you to the `demo` role to give you access to this target. Make sure to add this role to any new users if you want them to access it too."
        },
        "links": {
          "api": "Learn about Boundary API",
          "cli": "Learn about Boundary command-line (CLI)",
          "install": "Install the Boundary Desktop app",
          "learn": "Learn about Boundary Desktop app"
        },
        "titles": {
          "connect": "Connect to your target",
          "controller": "Controller URL",
          "creation-success": "We have created your scopes and target",
          "desktop": "Using Boundary Desktop",
          "developers": "Developers"
        }
      },
      "title": "Onboarding"
    },
    "questions": {
      "abandon-confirm": "Are you sure you want to navigate away?  Your unsaved changes will be lost.",
      "delete-confirm": "Are you sure you want to delete this resource?",
      "remove-confirm": "Are you sure you want to remove this association?",
      "retry-confirm": "Would you like to retry?"
    },
    "resources": {
      "account": {
        "actions": {
          "create": "Create Account",
          "delete": "Delete Account"
        },
        "description": "An account allows a user to log into Boundary with a particular auth method. Users may have multiple accounts for different auth methods.",
        "messages": {
          "set-password": {
            "description": "Set a password for the account."
          },
          "welcome": {
            "description": "Create and manage accounts for an auth method.",
            "title": "Welcome to Accounts"
          }
        },
        "title": "Account",
        "title_plural": "Accounts",
        "types": {
          "oidc": "OIDC",
          "password": "Password"
        }
      },
      "auth-method": {
        "actions": {
          "create": "Create Auth Method",
          "delete": "Delete Auth Method",
          "make-primary": "Make Primary",
          "remove-as-primary": "Remove as Primary"
        },
        "description": "Auth methods allow users to authenticate within a scope.",
        "messages": {
          "no-primary-auth-method": {
            "description": "A primary auth method auto-creates users when they authenticate for the first time.  Scopes without primary auth methods may experience authentication failures.",
            "title": "Recommendation: Select a Primary Auth Method"
          },
          "none": {
            "description": "No auth methods were found for this scope.",
            "title": "No Auth Methods"
          },
          "pending": {
            "description": "Complete authentication in the newly-opened window.",
            "title": "Authentication Pending"
          },
          "welcome": {
            "description": "Auth methods are configurations to authenticate users.",
            "title": "Welcome to Auth Methods"
          }
        },
        "notifications": {
          "make-primary-success": "Auth method successfully elected to primary.",
          "remove-as-primary-success": "Auth method successfully removed as primary."
        },
        "questions": {
          "make-primary": "Make primary auth method?",
          "make-primary-confirm": "A primary auth method auto-creates users on their first authentication attempt if needed.  Note:  if you change the primary auth method, the previous primary will no longer auto-create users.",
          "no-see-window": "Don't see the new window?",
          "remove-as-primary": "Remove primary auth method?",
          "remove-as-primary-confirm": "Removing the primary auth method may lead to authentication failures for this scope."
        },
        "title": "Auth Method",
        "title_plural": "Auth Methods",
        "titles": {
          "new-allowed-audience": "New Allowed Audience",
          "new-certificate": "New Certificate",
          "new-claims-scope": "New Claims Scope",
          "new-from-claim": "New From Claim",
          "new-signing-algorithm": "New Signing Algorithm",
          "new-to-claim": "New To Claim"
        },
        "types": {
          "oidc": "OIDC",
          "password": "Password"
        }
      },
      "credential": {
        "actions": {
          "create": "New Credential",
          "delete": "Delete Credential"
        },
        "description": "Credentials are static resources like usernames and passwords.",
        "form": {
          "password": {
            "help": "The password to use when signing in with this credential store.",
            "label": "Password"
          },
          "private_key": {
            "help": "The private key to use when connecting with this credential store.",
            "label": "SSH Private Key"
          },
          "private_key_passphrase": {
            "help": "Optional.",
            "label": "Passphrase"
          },
          "username": {
            "help": "The username to use when connecting with this credential store.",
            "label": "Username"
          }
        },
        "help": {
          "ssh_private_key": "This host requires a username, public key, and private key to connect.",
          "username_password": "This host requires a username and password to connect."
        },
        "messages": {
          "none": {
            "description": "No Credentials available in this credential store.",
            "title": "No Credentials Available"
          }
        },
        "title": "Credential",
        "title_plural": "Credentials",
        "types": {
          "ssh_private_key": "Username & Key Pair",
          "unknown": "Unknown",
          "username_password": "Username & Password"
        }
      },
      "credential-library": {
        "actions": {
          "create": "New Credential Library",
          "delete": "Delete Credential Library"
        },
        "description": "A credential library is a resource that provides credentials.",
        "form": {
          "http_method": {
            "help": "The HTTP method the library uses when requesting credentials from Vault.",
            "label": "HTTP Method"
          },
          "http_request_body": {
            "help": "The body of the HTTP request the library sends to Vault when requesting credentials. Only allowed when HTTP method is set to POST.",
            "label": "HTTP Method POST Request Body"
          },
          "vault_path": {
            "help": "The path the library uses when requesting credentials from Vault.",
            "label": "Vault Path"
          }
        },
        "messages": {
          "none": {
            "description": "No Credential Libraries available in this credential store.",
            "title": "No Credential Libraries Available"
          }
        },
        "title": "Credential Library",
        "title_plural": "Credential Libraries",
        "types": {
          "static": "Static",
          "vault": "Vault"
        }
      },
      "credential-store": {
        "actions": {
          "delete": "Delete Credential Store"
        },
        "description": "A credential store is a collection of credentials and credential libraries.",
        "form": {
          "address": {
            "help": "The address of the Vault server. This should be a complete URL such as https://127.0.0.1:8200.",
            "label": "Address"
          },
          "ca_cert": {
            "help": "A PEM-encoded CA certificate to verify the Vault server's TLS certificate.",
            "label": "CA Certificate"
          },
          "client_certificate": {
            "help": "A PEM-encoded client certificate to use for TLS authentication to the Vault server.",
            "label": "Client Certificate"
          },
          "client_certificate_key": {
            "help": "A PEM-encoded private key matching the client certificate from Client Certificate.",
            "label": "Client Certificate Key"
          },
          "client_certificate_key_hmac": {
            "label": "Client Certificate Key HMAC"
          },
          "namespace": {
            "help": "A Vault namespace. Requires Vault Enterprise.",
            "label": "Namespace"
          },
          "tls_server_name": {
            "help": "Name to use as the SNI host when connecting to Vault via TLS.",
            "label": "TLS Server Name"
          },
          "tls_skip_verify": {
            "help": "Disable verification of TLS certificates. Using this option is highly discouraged as it decreases the security of data transmissions to and from the Vault server.",
            "label": "TLS Skip Verify"
          },
          "token": {
            "help": "A token used for accessing Vault.",
            "label": "Token"
          },
          "token_hmac": {
            "label": "Token HMAC"
          }
        },
        "help": {
          "static": "This host uses static credentials, such as a username and password or key pair.",
          "vault": "The host details are brokered by a Vault server."
        },
        "messages": {
          "welcome": {
            "title": "Welcome to Credential Stores"
          }
        },
        "title": "Credential Store",
        "title_plural": "Credential Stores",
        "types": {
          "static": "Static",
          "vault": "Vault"
        }
      },
      "group": {
        "actions": {
          "add-members": "Add Members",
          "delete": "Delete Group"
        },
        "description": "Groups are collections of users. Groups may be assigned to roles to receive the role’s grants.",
        "messages": {
          "add-members": {
            "description": "Select users to add users to this group.",
            "title": "Add Members"
          },
          "members": {
            "description": "Members are the users assigned to a group.",
            "title": "Members"
          },
          "no-members": {
            "description": "There are no members available to add to this group.",
            "title": "No Members Available"
          },
          "welcome": {
            "title": "Welcome to Groups"
          }
        },
        "title": "Group",
        "title_plural": "Groups"
      },
      "host": {
        "actions": {
          "add": "Add Host",
          "create": "New Host",
          "delete": "Delete Host"
        },
        "description": "A host is a resource that may be accessed by a Boundary target.",
        "messages": {
          "none": {
            "description": "No hosts available in this scope.",
            "title": "No Hosts"
          },
          "none-friendly": {
            "description": "No hosts available to display yet.",
            "title": "No Hosts Available"
          },
          "welcome": {
            "title": "Welcome to Hosts"
          }
        },
        "title": "Host",
        "title_plural": "Hosts"
      },
      "host-catalog": {
        "actions": {
          "delete": "Delete Host Catalog"
        },
        "description": "A host catalog is a collection of hosts and host sets.",
        "form": {
          "aws_region": {
            "help": "AWS region of the hosts to be added to the catalog.",
            "label": "AWS Region"
          },
          "azure_client_id": {
            "help": "The client (application) ID of an Azure service principal that Boundary will use to authenticate and discover hosts from Azure.",
            "label": "Client/Application ID"
          }
        },
        "help": {
          "dynamic": "Automate discovery of target hosts and services on Azure or AWS",
          "static": "Provide a list of hosts and host sets with static addresses"
        },
        "messages": {
          "welcome": {
            "title": "Welcome to Host Catalogs"
          }
        },
        "title": "Host Catalog",
        "title_plural": "Host Catalogs",
        "type": "Type",
        "types": {
          "aws": "AWS",
          "azure": "Azure",
          "dynamic": "Dynamic",
          "static": "Static",
          "unknown": "Plugin"
        }
      },
      "host-set": {
        "actions": {
          "add": "Add Host Set",
          "add-hosts": "Add Existing Host",
          "create": "New Host Set",
          "create-and-add-host": "Create and Add Host",
          "delete": "Delete Host Set"
        },
        "description": "A host set is a collection of hosts within a host catalog.",
        "form": {
          "filter": {
            "aws": {
              "help": "Create a filter to select resources using values such as tag name or tag value. The AWS filter format is tag:<tag-name>=<tag-value>. For example:tag:application=production"
            },
            "azure": {
              "help": "Create a filter to select resources using values such as tag name or tag value. The Azure filter format is:tagName eq ‘<tag-name>’ and tagValue eq ‘<tag-value>'. For example:tagName eq 'application' and tagValue eq 'production'"
            },
            "label": "Filter"
          },
          "preferred_endpoints": {
            "help": "Optional. Preferred address at which the host should be accessed when multiple options are present, in cidr:<valid IPv4/6 CIDR> or dns:<globbed name> format.",
            "label": "Preferred Endpoints"
          }
        },
        "host": {
          "messages": {
            "add": {
              "description": "Select hosts that belong to this host set.",
              "title": "Add Hosts"
            },
            "add-none": {
              "description": "No hosts available for selection.",
              "title": "No Hosts Available"
            },
            "none": {
              "description": "No hosts available in this host set.",
              "title": "No Hosts"
            }
          }
        },
        "messages": {
          "welcome": {
            "title": "Welcome to Host Sets"
          }
        },
        "title": "Host Set",
        "title_plural": "Host Sets"
      },
      "managed-group": {
        "actions": {
          "create": "Create Managed Group",
          "delete": "Delete Managed Group"
        },
        "description": "A managed group represents a collection of accounts.",
        "member": {
          "messages": {
            "description": "No members in this managed group.",
            "title": "Welcome to Members"
          },
          "title": "Member",
          "title_plural": "Members"
        },
        "messages": {
          "welcome": {
            "description": "Create and manage managed groups for an auth method.",
            "title": "Welcome to Managed Groups"
          }
        },
        "title": "Managed Group",
        "title_plural": "Managed Groups"
      },
      "org": {
        "actions": {
          "delete": "Delete Org"
        },
        "description": "An org is a type of scope used to organize projects and IAM resources.  Orgs are child scopes of the global scope.",
        "messages": {
          "settings": {
            "title": "Org Settings"
          },
          "welcome": {
            "title": "Welcome to Orgs"
          }
        },
        "title": "Org",
        "title_plural": "Orgs"
      },
      "project": {
        "actions": {
          "delete": "Delete Project"
        },
        "description": "A project is a type of scope used to organize resources such as targets and host catalogs.",
        "messages": {
          "settings": {
            "title": "Project Settings"
          },
          "welcome": {
            "title": "Welcome to Projects"
          }
        },
        "title": "Project",
        "title_plural": "Projects"
      },
      "role": {
        "actions": {
          "create": "Create Role",
          "delete": "Delete Role"
        },
        "description": "Roles are collections of capability grants and the principals (users and groups) assigned to them.",
        "grant": {
          "actions": {
            "create": "New Grant"
          },
          "description": "Grants are permissions which allow roles to take actions and access resources.",
          "title": "Grant",
          "title_plural": "Grants"
        },
        "messages": {
          "welcome": {
            "title": "Welcome to Roles"
          }
        },
        "principal": {
          "actions": {
            "add-principals": "Add Principals"
          },
          "description": "Principals are the users and groups assigned to a role and bounded by a role’s grants.",
          "messages": {
            "add-principals": {
              "description": "Select users and groups to assign to this role.",
              "title": "Add Principals"
            },
            "none": {
              "description": "Principals are not available for assignment.",
              "title": "No Principals Available"
            },
            "welcome": {
              "description": "Principals are not assigned in this role.",
              "title": "Welcome to Principals"
            }
          },
          "title": "Principal",
          "title_plural": "Principals",
          "types": {
            "group": "Group",
            "user": "User"
          }
        },
        "title": "Role",
        "title_plural": "Roles"
      },
      "scope": {
        "messages": {
          "cannot_read": "While you do not have permission to read this scope, you may have permission to view resources within it."
        },
        "title": "Scope",
        "title_plural": "Scopes"
      },
      "session": {
        "actions": {
          "connect": "Connect"
        },
        "active_plural": "Active Sessions",
        "credential": {
          "actions": {
            "raw-api": {
              "hide": "Hide raw API output",
              "show": "View raw API output"
            }
          },
          "credential_source": {
            "title": "Credential Source"
          },
          "secret": {
            "label": "Secret"
          },
          "title": "Credential",
          "title_plural": "Credentials"
        },
        "description": "A session is a connection to a target initiated through Boundary.",
        "messages": {
          "none": {
            "description": "There are no active sessions within the current scope.",
            "title": "No Sessions"
          },
          "none-active-friendly": {
            "description": "No active sessions available to display yet.",
            "title": "No Active Sessions Available"
          },
          "none-friendly": {
            "description": "No sessions available to display yet.",
            "title": "No Sessions Available"
          }
        },
        "status": {
          "active": "Active",
          "canceling": "Canceling",
          "pending": "Pending",
          "terminated": "Terminated"
        },
        "title": "Session",
        "title_plural": "Sessions"
      },
      "target": {
        "actions": {
          "add-brokered-credential-sources": "Add Brokered Credentials",
          "add-host-sources": "Add Host Sources",
          "add-injected-application-credential-sources": "Add Injected Application Credentials",
          "create": "New Target",
          "delete": "Delete Target"
        },
        "brokered-credential-source": {
          "messages": {
            "add": {
              "description": "Select brokered credential sources to assign to this target.",
              "title": "Add Brokered Credential Sources"
            },
            "none": {
              "description": "No brokered credential sources available to add to this target.",
              "title": "No Brokered Credential Sources Available"
            },
            "welcome": {
              "description": "No brokered credential sources in this target.",
              "title": "Welcome to brokered credentials"
            }
          },
          "title": "Brokered Credential",
          "title_plural": "Brokered Credentials"
        },
        "description": "A target is a logical collection of host sets which may be used to initiate sessions.",
        "help": {
          "ssh": "Protocol-aware connections are made over SSH.  Use when requiring SSH connections to remote hosts.",
          "tcp": "Connections are made over TCP to support a broad range of protocols, including SSH, RDP, databases, and more.  This is the most general-purpose type."
        },
        "host-source": {
          "messages": {
            "add": {
              "description": "Select host sources to assign to this target.",
              "title": "Add Host Sources"
            },
            "none": {
              "description": "No hosts sources available to add to target.",
              "title": "No Host Sources Available"
            },
            "welcome": {
              "description": "No hosts sources in this target.",
              "title": "Welcome to Host Sources"
            }
          },
          "title": "Host Source",
          "title_plural": "Host Sources"
        },
        "injected-application-credential-source": {
          "messages": {
            "add": {
              "description": "Select injected Application credential sources to assign to this target.",
              "title": "Add Injected Application Credential Sources"
            },
            "none": {
              "description": "No Injected Application credential sources available to add to this target.",
              "title": "No Injected Application Credential Sources Available"
            },
            "welcome": {
              "description": "No Injected application credential sources in this target.",
              "title": "Welcome to Injected Application credentials"
            }
          },
          "title": "Injected Application Credential",
          "title_plural": "Injected Application Credentials"
        },
        "messages": {
          "connection-details": {
            "title": "Target Connection Details"
          },
          "connection-success": {
            "title": "Successfully Connected"
          },
          "none": {
            "description": "No Targets to display yet. Contact your Boundary admin if you don't see a Target you expect access to.",
            "title": "No Targets Available"
          },
          "proxy_url": {
            "title": "Proxy URL"
          },
          "welcome": {
            "title": "Welcome to Targets"
          }
        },
        "title": "Target",
        "title_plural": "Targets",
        "types": {
          "ssh": "SSH",
          "tcp": "TCP"
        }
      },
      "user": {
        "actions": {
          "add-accounts": "Add Accounts",
          "delete": "Delete User"
        },
        "description": "Users are entities authorized to access Boundary.  Users may be assigned to roles as principals, thus receiving role grants.",
        "messages": {
          "accounts": {
            "description": "Users may be associated with any number of accounts.",
            "title": "Accounts"
          },
          "add-accounts": {
            "description": "Select accounts to associate with this user.",
            "title": "Add Accounts"
          },
          "no-accounts": {
            "description": "There are no accounts available to add to this user.",
            "title": "No Accounts Available"
          },
          "welcome": {
            "title": "Welcome to Users"
          }
        },
        "title": "User",
        "title_plural": "Users"
      },
      "worker": {
        "actions": {
          "delete": "Remove Worker",
          "register": "Register Worker"
        },
        "description": "Workers are the server components that perform the session handling.",
        "form": {
          "cluster_id": {
            "help": "The cluster ID is the UUID in the HCP Boundary Controller URL.",
            "label": "Boundary Cluster ID"
          },
          "config_file_path": {
            "help": "The path on your server where you will store your configuration file.",
            "label": "Config file path"
          },
          "initial_upstreams": {
            "help": "A list of hosts/IP addresses and optionally ports for reaching Boundary cluster.  The port will default to :9201 if not specified.",
            "label": "Initial Upstreams"
          },
          "ip_address": {
            "help": "Public IP or address.",
            "label": "Worker public address"
          },
          "steps": {
            "1": {
              "create_config": "Open the file with a text editor, such as Vi. Paste the following configuration into the worker config file:",
              "create_directory": "Create a configuration file in a new directory on your server",
              "save_config": "Save the configuration in your `pki-worker.hcl` file.",
              "title": "1. Configure your new worker"
            },
            "2": {
              "copy_registration_request": "When the server starts, it will print details related to this worker. Copy the value for",
              "run_command": "Run this command on your server to install Boundary:",
              "title": "2. Install & start Boundary on your server"
            },
            "3": {
              "registered": "Registered",
              "title": "3. Confirm the worker when they connect",
              "worker_auth_registration_request": {
                "help": "The registration key provided by this worker when it started",
                "label": "Worker Auth Registration Request"
              }
            }
          },
          "worker_tags": {
            "help": "Boundary can use tags to define key-value pairs which targets can use to determine where they should route connections.",
            "label": "Worker Tags"
          }
        },
        "messages": {
          "welcome": {
            "title": "Welcome to Workers"
          }
        },
        "table": {
          "ip_address": "IP Address",
          "release_version": "Release Version",
          "session_count": "Session Count",
          "tag_count": "{tagCount, plural, =1 {# tag} other {# tags}}"
        },
        "title": "Worker",
        "title_pki": "New PKI Worker",
        "title_plural": "Workers"
      }
    },
    "states": {
      "active": "Active",
      "active-private": "Private",
      "active-public": "Public",
      "copied": "Copied",
      "error": "Error",
      "inactive": "Inactive",
      "info": "Info",
      "primary": "Primary",
      "success": "Success",
      "warning": "Warning"
    },
    "themes": {
      "dark": "Dark",
      "light": "Light",
      "system": "System Default"
    },
    "titles": {
      "abandon-confirm": "Discard unsaved changes?",
      "actions": "Actions",
      "choose-a-provider": "Choose a provider",
      "create-resources": "Create Resources",
      "details": "Details",
      "documentation": "Documentation",
      "empty-set": "No Items Yet",
      "general": "General",
      "global": "Global",
      "home": "Home",
      "iam": "IAM",
      "label": "Label",
      "new": "New",
      "no-results": "No Results",
      "overview": "Overview",
      "provider": "Provider",
      "resources": "Resources",
      "settings": "Settings",
      "success": "Success",
      "toggle-color-theme": "Toggle Color Theme",
      "value": "Value",
      "welcome-to-boundary": "Welcome to Boundary"
    }
  }]];
  _exports.default = _default;
});